.root {
    --fixedTopOffsetMobile: 0px;
    --fixedTopOffsetTablet: 0px;
    --fixedTopOffsetPC: 0px;
    --fixedTopOffset: var(--fixedTopOffsetMobile);
    --headerHeight: 40px;
    --headerPaddingBottom: 12px;
    --headerPaddingTop: 8px;
    --headerHeighWithPaddings: calc(var(--headerHeight) + calc(var(--headerPaddingTop) + var(--headerPaddingBottom)));
    --marginTopMobile: 0px;
    --marginTopTablet: 0px;
    --marginTopPC: 0px;
    --pageHeight: 100vh;
    --padding-content: 0 12px;
    margin-bottom: 40px;
    margin-top: var(--marginTopMobile);
    width: 100%;

    @media (--snow-tablet) {
        --fixedTopOffset: var(--fixedTopOffsetTablet);
        margin-bottom: 56px;
        margin-top: var(--marginTopTablet);
    }

    @media (--snow-desktop) {
        --fixedTopOffset: var(--fixedTopOffsetPC);
        margin-top: var(--marginTopPC);
    }
}

.tabs {
    overflow: hidden;
}

.header {
    background-color: #fff;
    display: flex;
    flex-direction: row;
    overflow: hidden;
    overflow-x: scroll;
    padding: 0;
    padding-bottom: var(--headerPaddingBottom);
    padding-top: var(--headerPaddingTop);
    scrollbar-width: none;
    z-index: 10;

    &::-webkit-scrollbar {
        display: none;
    }
}

.fixedHeader {
    margin-top: 0;
    position: fixed;
    top: var(--fixedTopOffsetMobile);
    width: 100%;

    @media (--snow-tablet) {
        top: var(--fixedTopOffsetTablet);
    }

    @media (--snow-desktop) {
        top: var(--fixedTopOffsetPC);
    }
}

.title {
    &:first-of-type {
        padding-left: 12px;
    }
    &:last-of-type {
        padding-right: 12px;
    }

    @media (--snow-tablet) {
        &:first-of-type {
            padding-left: 0;
        }
        &:last-of-type {
            padding-right: 0;
        }
    }

    .tab {
        border: none;
        text-decoration: none;
        text-underline-offset: 4px;
        font-size: 13px;
        line-height: 16px;
        font-weight: 500;
        font-style: normal;
        color: #a0a0ab;
        padding: 8px 6px;
        letter-spacing: 0px;
        height: 32px;

        span {
            font-weight: 500;
            line-height: 32px;
        }

        &.selected {
            color: #18181b;
            text-decoration: underline;
        }
    }

    @media (--snow-tablet) {
        .tab {
            font-size: 15px;
            line-height: 18px;
            text-underline-offset: 10px;
            padding: 10px 6px;
            height: 38px;

            span {
                font-weight: 500;
                line-height: 38px;
            }
        }
    }
}

.loader {
    align-items: center;
    display: flex;
    justify-content: center;
    padding-bottom: 16px;
    padding-top: 96px;
    width: 100%;
}

.content {
    padding: var(--padding-content);
    min-height: calc(
        var(--pageHeight) - var(--headerHeighWithPaddings)
    ); /* This rule prevents fixed header bouncing because of content collapsing after selecting new tab. */
}

.fixedContent {
    margin-top: var(
        --headerHeighWithPaddings
    ); /* This rule prevents fixed header bouncing because of content collapsing after selecting new tab. */
}

.intersectionContainer {
    position: relative;
}

.intersectionTop {
    position: absolute;
    top: calc(-1 * var(--fixedTopOffset));
}

.intersectionBottom {
    bottom: calc(var(--fixedTopOffset) + var(--headerHeight));
    left: 0;
    position: absolute;
}

@media (--snow-tablet) {
    .titleHeading {
        padding-left: 0;
    }

    .loader {
        padding-top: 56px;
    }
}
